<template>
  <div class="index_container inviteDoctor">
    <homeItem :item="data" @geToMeet="geToMeet" :mode="2"></homeItem>
    <Popup
      :msg="config.msg"
      @doSure="doSure()"
      @doClose="doClose()"
      v-if="config.msg.isShow"
    >
      <div class="popup_main">
        <el-select
          v-model="value"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="请输入专家姓名"
          remote-show-suffix
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in config.doctorOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="popup_desc colorRed">{{ config.msg.content }}</div>
      </div>
    </Popup>
  </div>
</template>
<script>
import Popup from "../../unit/Popup.vue";
// import CustomButton from "@/components/unit/CustomButton";
import homeItem from "../home/homeItem.vue";
export default {
  name: "inviteDoctor",
  components: {
    // CustomButton,
    homeItem,
    Popup,
  },
  data() {
    return {
      config: {
        invite: true,
        msg: {
          isShow: false,
          title: "指定邀请",
          align: "center",
          content: "注: 遵请后需专家回帖后，才算邀请成功",
          btngroup: { close: "取消邀请", sure: "确认邀请" },
        },
        doctorOptions: [],
      },
      data: {},
      loading: false,
      value: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let url = this.$tools.getURL(this.$urls.live.detail, this.$route.query);
      this.$axios.get(url).then((res) => {
        this.data = res.data;
      });
    },
    //自定义远程搜索方法
    remoteMethod(query) {
      console.log("query :>> ", query);
    },
    //邀请
    geToMeet(meetingInfoIndex, index) {
      console.log("index :>> ", meetingInfoIndex, index);
      this.config.msg.isShow = true;
    },
    doSure() {
      this.$router.push({ path: "/home" });
      this.doClose();
    },
    doClose() {
      this.config.msg.isShow = false;
    },
  },
};
</script>
<style >
.index_container {
  height: 100%;
  width: 100%;
}
.index_test {
  width: 12.5rem;
}
.inviteDoctor .popup_main {
  display: flex;
  flex-direction: column;
}
.inviteDoctor .popup_desc {
  font-size: 0.75rem;
  margin: 0.5rem 0;
}
.inviteDoctor .el-input,
.inviteDoctor .el-select__input {
  font-size: 1rem;
}
</style>